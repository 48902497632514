import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {BACKEND_URL} from '../const';

const LoginForm = () => {
  const {t} = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      console.log(email, password);
      const response = await axios.post(`${BACKEND_URL}/auth`, {email, password});
      console.log(response.data);
      if (response.data?.status === "OK" && response.data?.data) {
        const token = response.data.data;
        localStorage.setItem('token', token);
        navigate('/home');
      }
      // else {
      //   throw new Error(response.data?.message);
      // }
      // else if (response.data.status === 'ERROR') {
      //   setErrorMessage(t('invalidCredentials')); // todo használhatod a nemzetköziítést hibaüzenetekhez is todo nyelvesítés és bővítés
      // }
    } catch (error) {
      setErrorMessage('' + error); // todo ezt is
    }
  };

  return (
      <form className="flex flex-col " onSubmit={handleSubmit}>
        {errorMessage && <p className="mb-2 text-red-500">{errorMessage}</p>}
        <input
            className="mb-2 p-2 bg-cyan-50  rounded-2xl shadow-lg"
            type="email"
            placeholder={t('emailPlaceholder')}
            value={email}
            onChange={e => setEmail(e.target.value)}
            required={true}
            autoFocus={true}
        />
        <input
            className="mb-2 p-2 bg-cyan-50  rounded-2xl shadow-lg"
            type="password"
            placeholder={t('passwordPlaceholder')}
            value={password}
            onChange={e => setPassword(e.target.value)}
            required={true}
        />
        <button
            className="p-2 bg-cyan-500 hover:bg-cyan-700 text-white rounded-2xl shadow-lg">{t("signInBtn")}</button>
      </form>
  );
}

export default LoginForm;
