import React, {useEffect, useState} from "react";
import {BACKEND_URL} from "../const";
import axios from "axios";
import {useNavigate} from "react-router-dom";
//todo nyelvesítés
const Settings = ({user}) => {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'dark');
  const navigate = useNavigate();

  const handleLogout = async event => {
    // event.preventDefault();
    //itt lehet implementálni a kijelentkezési logikát
    // localStorage.removeItem('token');
    try { //todo ha kell bele olyan funkció, hogy mindenhonnan jelentkezzen ki
      // const token = localStorage.getItem('token');
      // if (token) {
      const response = await axios.delete(`${BACKEND_URL}/auth`);
      // console.log(response);
      // console.log('----');
      // console.log(response.data);
      // console.log('----');
      // console.log(response.data?.status === "OK");
      // console.log('----');
      // console.log(response.data?.status);
      // console.log('----');
      // console.log(response.statusText);
      // console.log('----');
      // console.log(response.status === 200);
      // if (response && response.status)
      if (response.data?.status !== "OK") {
        throw new Error("Invalid response");
      }
      // else {
      //   localStorage.removeItem('token');
      //   navigate('/');
      // }
      // } else {
      //   throw new Error("Token is missing");
      // }
      console.log("Kijelentkezés");
    } catch (error) {
      console.error('Failed to logout:', error);
    } finally {
      localStorage.removeItem('token');
      navigate('/');
    }
  };

  const handleUserEdit = () => {
    // navigáció a felhasználói adatok szerkesztési oldalára vagy megnyitás egy modális ablakban
    console.log("Felhasználói adatok szerkesztése");


  };

  const toggleDarkMode = () => {
    // sötét mód kapcsolása
    setDarkMode(!darkMode);
    console.log("Sötét mód:", !darkMode ? "Bekapcsolva" : "Kikapcsolva");
  };

  useEffect(() => {
    document.body.classList.toggle('dark', darkMode);
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  // További menüpont ötletek:
  // - Értesítési beállítások
  // - Jelszó megváltoztatása
  // - Adatvédelmi opciók
  // - Nyelvi beállítások

  // return (
  //     <div className="p-4">
  //       <h1 className="text-xl font-bold mb-4">Beállítások</h1>
  //       <ul className="list-disc pl-5">
  //         <li className="mb-2">
  //           <button onClick={handleUserEdit} className="text-indigo-600 underline focus:outline-none">
  //             Felhasználói adatok módosítása
  //           </button>
  //         </li>
  //         <li className="mb-2">
  //           <button onClick={toggleDarkMode} className="text-indigo-600 underline focus:outline-none">
  //             Sötét téma {darkMode ? 'kikapcsolása' : 'bekapcsolása'}
  //           </button>
  //         </li>
  //         <li className="mb-2">
  //           {/* További menüpontok helye */}
  //         </li>
  //         <li className="mb-2">
  //           <button onClick={handleLogout} className="text-red-600 underline focus:outline-none">
  //             Kijelentkezés
  //           </button>
  //         </li>
  //       </ul>
  //     </div>
  // );

  return (
      <div
          className="p-6 pt-20 min-h-screen text-white">
        <p className="text-2xl font-semibold mb-6 text-black dark:text-white">Beállítások</p>
        <p className={"text-black"}>{user.name}</p>

        <div className="mb-4">
          <button
              onClick={handleUserEdit}
              className="w-full p-2 bg-teal-800 hover:bg-blue-500 rounded-md"
          >
                    <span className="flex items-center">
                        Felhasználói adatok módosítása
                    </span>
          </button>
        </div>

        <div className="mb-4">
          <button
              onClick={toggleDarkMode}
              className="w-full p-2 bg-teal-800 hover:bg-blue-500 rounded-md"
          >
                    <span className="flex items-center">
                        Sötét téma {darkMode ? 'kikapcsolása' : 'bekapcsolása'}
                    </span>
          </button>
        </div>

        {/* További menüpontok helye */}

        <div className="mt-2">
          <button
              onClick={handleLogout}
              className="w-full p-2 bg-red-700 rounded-md hover:bg-red-500"
          >
                    <span className="flex items-center">
                        Kijelentkezés
                    </span>
          </button>
        </div>
      </div>
  );
}

export default Settings;
